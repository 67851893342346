/* eslint-disable no-restricted-globals */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { useState, useEffect, useRef, memo, createRef } from 'react';
import ReactDOMServer from "react-dom/server";
import { add, forEach, set } from "lodash";
import PdfImage from "../../../assets/image/pdf.png";
import InputFile from "../InputFile.js";
import MarkdownFastProgressiveRender from "../markdownFastRender.js";
import MessageOptionBar from "../messageOptionBar.js";
import Markdown from "../../Markdown.js";
// eslint-disable-next-line import/no-webpack-loader-syntax
import wowo from "worker-loader!../streamWorker.js";
//import 'katex/dist/katex.min.css';
import MessageList from "../component/messageList.js";
import ActionsComponent from "../actionComponent.js";
import FeedBack from "../component/feedback.js";
import History from "../component/history.js";
import {AI_URL} from "../../../components/common/constant";
//on prend une div
const worker = new wowo();

//tenter une approche avec un tableau de deiv pour les messages et éviter l'update complet de la page

function useAutosizeTextarea(value,messages) {
    const textAreaRef = useRef(null);

    useEffect(() => {
        console.log(messages);
        if(Object.keys(messages).length == 1){
            return textAreaRef.current.style.height = '200px';
        }


        if (textAreaRef.current) {
            textAreaRef.current.style.height = '0px';
            const { scrollHeight } = textAreaRef.current;
            if (scrollHeight > 270) {
                return textAreaRef.current.style.height = `270px`;
            }
            textAreaRef.current.style.height = `${scrollHeight + 5}px`;
        }
    }, [value,messages]);

    return textAreaRef;
}

const Spinner = () => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z" />
      <rect
        className="spinner_d9Sa spinner_qQQY"
        x="11"
        y="6"
        rx="1"
        width="2"
        height="7"
      />
      <rect
        className="spinner_d9Sa spinner_pote"
        x="11"
        y="11"
        rx="1"
        width="2"
        height="9"
      />
    </svg>
);

function FreeChat({ session, userDetails }) {
    const [userInput, setUserInput] = useState('');
    const chatBoxRef = useRef(null);
    const inputFileRef = createRef();
    const [autoScroll, setAutoScroll] = useState(true);
    const [messages, setMessages] = useState({
        0: { type: 'ia', message: "<h4>Pose n’importe quelle question qui peut t’aider dans ton travail !</h4>" }
    });
    const [fileUrl, setFileUrl] = useState([]);
    const textAreaRef = useAutosizeTextarea(userInput,messages);
    const userData = userDetails.data;
    const [userLevelInput, setUserLevelInput] = useState(userData?.level ?? '');
    const [userSubjectInput, setUserSubjectInput] = useState('');
    const introductionMessageLength = 1;
    const [threadId, setThreadId] = useState(-1);
    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };
    const historyRef = useRef(null);
    

    function imageLoaded(e) {
        chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight);
    }
    window.imageLoaded = imageLoaded;//for call function for html 

    const convertLatexDelimiters = (text) => {
        text = text 
        .replace(/\\\[/g, '$$$')  // Replace all occurrences of \[ with $$
        .replace(/\\\]/g, '$$$') // Replace all occurrences of \] with $$
        .replace(/\\\(/g, '$$')  // Replace all occurrences of \( with $$
        .replace(/\\\)/g, '$$'); // Replace all occurrences of \) with $$
        return text;
    };

    const SimpleFetch = async (url, type = "POST", data = {}) => {
        const response = await fetch(url, {
            method: type,
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          });
        
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        
          return await response.json();
    }

    worker.onmessage = (event) => {
        let { id, text, baseContent, additionnalData, done } = event.data;
        if(additionnalData !== undefined){
            additionnalData = JSON.parse(additionnalData);
        }
        setMessagesForStream(text, id, baseContent, additionnalData);
        if(done){
            historyRef.current.loadHistory();
        }
        console.log(event.data)
    };

    const setMessagesForStream = (message, id, baseContent, additionnalData) => {
        setMessages((prevMessages) => ({
            ...prevMessages,
            [id]: { type: 'ia', message: message, baseContent: baseContent, additionnalData : additionnalData },
        }));
        if (autoScroll) {
            chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight);
        }
    };
    const handleSend = async (e) => {
        let requireThread = Object.keys(messages).length == 1 ? true : false;
        var actualThreadId = threadId;
        if(requireThread){
            var tid = await SimpleFetch(`https://${AI_URL}/api/v1/createthread`, "POST", {userid : session.userid})
            setThreadId(tid.id);
            actualThreadId = tid.id;
        }
        console.log(actualThreadId)
        const params = { 
            "level" : userLevelInput,
            "threadId": actualThreadId,
            "subject" : userSubjectInput
        };
        setAutoScroll(true);
        e.preventDefault();
        if (!session.userid) return;

        if(Object.keys(messages).length == 1){
            //historyRef.current.loadHistory();
        }

        let html = "";
        forEach(fileUrl, (url) => {
            html += `<img src="${getUrlForExtension(url)}" onload="window.imageLoaded()" />`;
        });

        const id = Object.keys(messages).length;
        setMessages((prevMessages) => ({
            ...prevMessages,
            [id]: { type: 'user', message: `<div>` + userInput + html + "</div>"  },
        }));
       
        worker.postMessage({
            url:`https://${AI_URL}/api/v1/chat`,
            fileUrl,
            userInput,
            userid: session.userid,
            messageId: id + 1,
            params
        });

        setFileUrl([]);
        setUserInput("");
    };


    async function pdfResolve(){
        const params = { 
            "createThread" : Object.keys(messages).length == 1 ? true : false,
            "level" : userLevelInput,
            "subject" : userSubjectInput
        };
        setFileUrl([]);

        setAutoScroll(true);
        if (!session.userid) return;

        let html = "";
        forEach(fileUrl, (url) => {
            html += `<img src="${getUrlForExtension(url)}" onload="window.imageLoaded()" />`;
        });

        const id = Object.keys(messages).length;
        setMessages((prevMessages) => ({
            ...prevMessages,
            [id]: { type: 'user', message:  userInput + html   },
        }));

        /*set message while loading*/
        setMessages((prevMessages) => ({
            ...prevMessages,
            [id + 1]: { type: 'ia', message: ReactDOMServer.renderToString(<><h3>Réponse en cours de génération <Spinner></Spinner></h3></>) },
        }));

        setTimeout(() => {
            chatBoxRef?.current?.scrollTo(0, chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight);
        }, 50);
        var resp = await SimpleFetch(`https://${AI_URL}/api/v1/docresolve`, "POST", {question : userInput,fileUrl : fileUrl})
        setMessages((prevMessages) => ({
            ...prevMessages,
            [id + 1]: { type: 'ia', message: ReactDOMServer.renderToString(<Markdown className="markdown" >{convertLatexDelimiters(resp.data)}</Markdown>)  },
        }));
        setFileUrl([]);
        setUserInput("");
    }

    function generateResume(){
        const params = { 
            "createThread" : Object.keys(messages).length == 1 ? true : false,
            "level" : userLevelInput,
            "subject" : userSubjectInput
        };
        const id = Object.keys(messages).length;
        worker.postMessage({
            url:`https://${AI_URL}/api/v1/generateResume`,
            fileUrl,
            userInput,
            userid: session.userid,
            messageId: id,
            params
        });
    }

    function stopAutoScroll(e) {
        const wheelDirection = e.deltaY < 0 ? 'up' : 'down';
        if (wheelDirection == 'up') {
            setAutoScroll(false);
        } else if (wheelDirection == 'down') {
            const chat = chatBoxRef.current
            const isScrolledToBottom = chat.scrollHeight - chat.scrollTop  - chat.clientHeight <= chat.clientHeight + 1;
            if (isScrolledToBottom) {
                setAutoScroll(true);
            }
        }
    }

    const removeFileUrl = (urlToRemove) => {
        setFileUrl((prevUrls) => prevUrls.filter((url) => url !== urlToRemove));
    };

    const getUrlForExtension = (url) => {
        const extension = url.split('.').pop().toLowerCase();
        return extension === "pdf" ? PdfImage : url;
    };

    const transformResponseToQuizz = async (data) => {
        //fetch("https://ai.kojyto.fr/api/v1/quizz", {})
        setAutoScroll(true);
        chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight);
        const id = Object.keys(messages).length;
        worker.postMessage({url:`https://${AI_URL}/api/v1/generatequestionfromresponse`, fileUrl: [], userInput: data.baseContent, userid: session.userid, messageId: id });
        //var rslt = await SimpleFetch("https://ai.kojyto.fr/api/v1/generatequestionfromresponse", "POST", {userid : session.userid, text: response})
    }

    const treatMessageToMarkdown =  (mess) => {
        var allMessages = [];
        forEach(mess, (message, key) => {
            if(message.type !== "system"){
                allMessages.push({type:message.type == "ai" ? "ia" : message.type, message :ReactDOMServer.renderToString(<Markdown className="markdown" >{convertLatexDelimiters(message.message)}</Markdown>)});
            }
        });
        console.log("before",allMessages)
        console.log(allMessages)
        return allMessages.reverse();
    }

    const loadConversation = async (mess, tid) =>{
        setThreadId(tid)
        setMessages(treatMessageToMarkdown(mess));
    }

    const onNewConversation = async (mess) =>{
        setThreadId(-1)
        setMessages([{ type: 'ia', message: "<h4>Pose n’importe quelle question qui peut t’aider dans ton travail !</h4>" }]);
    }

    return (
<div className="h-max-[100vh] flex items-center justify-center bg-gray-50 p-4">
    
    <div className="w-full max-w-[98%] p-4 sm:p-6 bg-white rounded-lg shadow-md relative">
        
        <div className="flex flex-col items-center">
            <div className="text-xl sm:text-2xl font-bold mb-4 text-center">Kojyto AI 🎓</div>
        </div>
        <div className="absolute top-4 right-4 z-10 pointer-events-none h-full">
            <History ref={historyRef} userid={session.userid} loader={loadConversation} onnew={onNewConversation}></History>
        </div>

        <div>
            
            <MessageList 
                chatBoxRef={chatBoxRef}
                messages={messages}
                introductionMessageLength={1}
                dynamicActions={[
                    {
                        name: "Transformer en quiz kojyto",
                        cond: false, 
                        action: (message) => { transformResponseToQuizz(message); }
                    },
                    {
                        name: "Importer dans ma classe",
                        color: 'text-green-500 font-semibold',
                        cond: false, 
                        action: (message) => { transformResponseToQuizz(message); }
                    }
                ]}
            />

            <div className="mt-4 grid grid-cols-3 gap-2">
                {fileUrl.map((url, index) => (
                    <div key={index} className="relative">
                        <svg
                            onClick={() => removeFileUrl(url)}
                            className="absolute top-1 right-1 z-10 cursor-pointer"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="red"
                            width="20px"
                            height="20px"
                        >
                            <path d="M19.414 4.586a2 2 0 00-2.828 0L12 9.172 7.414 4.586a2 2 0 00-2.828 2.828L9.172 12l-4.586 4.586a2 2 0 002.828 2.828L12 14.828l4.586 4.586a2 2 0 002.828-2.828L14.828 12l4.586-4.586a2 2 0 000-2.828z" />
                        </svg>
                        <img className="w-full h-auto rounded" src={getUrlForExtension(url)} alt="Uploaded file" />
                    </div>
                ))}
            </div>

            <div className="mt-6 flex flex-col gap-4">
                <div className="flex flex-col sm:flex-row gap-4">
                    <input 
                        placeholder="Niveau" 
                        className="flex-1 p-3 bg-gray-100 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500" 
                        type="text"
                        value={userLevelInput}
                        onChange={(e) => setUserLevelInput(e.target.value)}
                    />
                    <input 
                        placeholder="Matière"
                        className="flex-1 p-3 bg-gray-100 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500" 
                        type="text"
                        value={userSubjectInput}
                        onChange={(e) => setUserSubjectInput(e.target.value)}
                    />
                </div>

                <div className="flex flex-col sm:flex-row gap-4 items-center">
                    <InputFile
                        ref={inputFileRef}
                        onFileIsUploaded={(url) => { setFileUrl((prevUrls) => [...prevUrls, url]); }}
                        resetFileUpload={() => { setFileUrl([]); }}
                    />
                    <textarea
                        ref={textAreaRef}
                        className="w-full p-3 bg-gray-100 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500"
                        placeholder="Tu peux me poser des questions sur n’importe quel sujet que tu étudies en cours, ou qui peut t’aider pour mieux comprendre tes devoirs. Tu peux demander des exercices d’entrainement. N’hésite pas à préciser ta classe, la matière et éventuellement le chapitre que tu étudies afin que les réponses soient les plus pertinentes."
                        value={userInput}
                        onChange={handleInputChange}
                        rows={3}
                    />
                    <div className="w-full sm:w-auto p-3 sm:p-4 rounded-lg text-center space-y-2">
                        <button
                            onClick={handleSend}
                            className="w-full sm:w-30 p-3 sm:p-4 bg-theme text-white rounded-lg text-center"
                        >
                            Envoyer
                        </button>

                        <FeedBack />
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
    );
}

const mapStateToProps = (state) => {
    return {
        session: state.session,
        userDetails: state.userDetails,
    };
};
  
const mapDispatchToProps = (dispatch) =>
bindActionCreators({},
    dispatch,
);



export default connect(mapStateToProps, mapDispatchToProps)(FreeChat);
