import React, { useState, useEffect, useCallback } from 'react';
import './xpanimation.css';

// Service d'animation XP global (singleton)
let xpAnimationCallbacks = [];
let xpAnimationContainer = null;

// Enregistrer le composant container
const registerXpAnimationContainer = (callbacks) => {
  xpAnimationCallbacks = callbacks;
  xpAnimationContainer = true;
  return () => {
    xpAnimationContainer = null;
    xpAnimationCallbacks = [];
  };
};

// API globale pour l'animation XP
export const xpAnimation = {
  add: (top, left, xp = 1) => {
    if (!xpAnimationContainer) {
      console.warn("XpAnimationContainer n'est pas monté dans le DOM");
      return null;
    }
    return xpAnimationCallbacks.addXpAnimation(top, left, xp);
  },
  remove: (id) => {
    if (!xpAnimationContainer) return;
    xpAnimationCallbacks.removeXpAnimation(id);
  }
};

// Composant pour une animation XP individuelle
const XpAnimation = ({ top, left, xp, id, removeXpAnimation }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      removeXpAnimation(id);
    }, 20000); // durée de l'animation : 1 seconde

    return () => clearTimeout(timer);
  }, [id, removeXpAnimation]);

  return (
    <div
      className="xp-animation"
      style={{
        position: 'absolute',
        top: top,   // Exemple : "100px"
        left: left, // Exemple : "200px"
      }}
    >
      +{xp} xp
    </div>
  );
};

// Container d'animation XP à placer dans votre application (ex : au niveau de votre App)
export const XpAnimationComp = () => {
  const [xpAnimations, setXpAnimations] = useState([]);

  // Fonction pour retirer une animation
  const removeXpAnimation = useCallback((id) => {
    setXpAnimations((prev) =>
      prev.filter((animation) => animation.id !== id)
    );
  }, []);

  // Fonction pour ajouter une nouvelle animation
  const addXpAnimation = useCallback((top, left, xp = 1) => {
    // Utilisation d'un ID basé sur Date.now() + aléatoire pour éviter les collisions
    const id = Date.now() + Math.floor(Math.random() * 1000);
    setXpAnimations((prev) => [...prev, { id, top, left, xp }]);
    return id;
  }, []);

  // Enregistrer ce composant comme container d'animation XP global
  useEffect(() => {
    const unregister = registerXpAnimationContainer({
      addXpAnimation,
      removeXpAnimation,
    });
    return unregister;
  }, [addXpAnimation, removeXpAnimation]);

  return (
    <div className="xp-animation-container" style={{ position: 'relative', width: '100%', height: '100%' }}>
      {xpAnimations.map(({ id, top, left, xp }) => (
        <XpAnimation
          key={id}
          id={id}
          top={top}
          left={left}
          xp={xp}
          removeXpAnimation={removeXpAnimation}
        />
      ))}
    </div>
  );
};

export default XpAnimationComp;