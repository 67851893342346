import React, {useState} from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { BadgeCheckIcon } from "@heroicons/react/outline";
import { viewAchivementModal, fetchStdOwnAchievements } from "@dashboardaction";
import {
  BASE_IMAGES_URL,
  getAge,
  itemCount,
  convertDateIntoLocal,
} from "@constant";
import LocaleStrings from "@language";
import StudentRewardsModal from "./student-rewards-modal";
import goldMedalIcon from "../../../assets/image/gold_medal.svg";
import silverMedalIcon from "../../../assets/image/silver_medal.svg";
import trophyIcon from "../../../assets/image/trophies.svg";
import badgeIcon from "../../../assets/image/badges.svg";
import bronzeMedalIcon from "../../../assets/image/bronze-medal.png";
import EmptyActivityImage from '../../../assets/image/EmptyPointIcone.svg'
import { useNavigate } from "react-router-dom";
import Markdown from "../../Markdown.js";

const popupOverlayStyle = {
  position: 'fixed',
  zIndex:"999",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const popupContentStyle = {
  background: '#fff',
  padding: '20px',
  maxWidth: '500px',
  borderRadius: '8px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)'
};

const buttonStyle = {
  backgroundColor: '#d9534f',
  color: '#fff',
  padding: '0.5rem 1rem',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  fontWeight: 600,
  transition: 'background-color 0.2s ease-in-out'
};

const Popup = ({ onClose }) => {

  const text = `
### *Gagnez des Points, des Médailles et Grimpez dans le Classement ! 🏆*  

Sur Kojyto, chaque quiz vous fait avancer ! *Accumulez des XP, décrochez des médailles chaque semaine et suivez votre progression dans le classement général.*  

#### *💡 Comment gagner des points ?*  
📖 *Mode Révision* → *+10 XP* si le quiz est complété.  
📝 *Mode Évaluation* → *+100 XP* si toutes les réponses sont correctes du premier coup.  

#### *🏅 Débloquez des Médailles chaque semaine !*  
Le classement hebdomadaire se remet à zéro chaque *dimanche à minuit*, alors jouez régulièrement pour atteindre le plus haut niveau !  

🥉 *Médaille de Bronze* → 5 quiz complétés  
🥈 *Médaille d’Argent* → 10 quiz complétés  
🥇 *Médaille d’Or* → 15 quiz complétés  

4*📈 Progressez dans le Classement Général !*  
💾 *Vos points sont cumulés de semaine en semaine, vous permettant de suivre **votre évolution sur le long terme*.  
📊 *Voyez votre rang progresser* et mesurez-vous aux autres joueurs !  

🔥 *Prêt à relever le défi ? Accumulez un max d’XP et visez le sommet !*
`;

  return (
    <div style={popupOverlayStyle}>
      <div style={popupContentStyle}>
        <Markdown className="markdown">{text}</Markdown>

        <button style={buttonStyle} onClick={onClose}>Fermer</button>
      </div>
    </div>
  );
};

const InlineButton = ({ onClick }) => {
  const baseStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '0.5rem 1rem', // équivalent à px-4 py-2
    backgroundColor: '#3B82F6', // bg-blue-500
    color: '#FFFFFF',           // text-white
    fontWeight: 600,            // font-semibold
    borderRadius: '9999px',     // rounded-full
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)', // shadow
    transition: 'background-color 0.2s ease-in-out',
    cursor: 'pointer'
  };

  const hoverStyle = {
    backgroundColor: '#2563EB' // hover:bg-blue-600
  };

  const focusStyle = {
    boxShadow: '0 0 0 2px #3B82F6, 0 1px 2px rgba(0, 0, 0, 0.05)' // focus:ring
  };

  const [currentStyle, setCurrentStyle] = useState(baseStyle);

  return (
    <button
      style={currentStyle}
      onClick={onClick} // Propagation de l'événement clic
      onMouseEnter={() => setCurrentStyle({ ...baseStyle, ...hoverStyle })}
      onMouseLeave={() => setCurrentStyle(baseStyle)}
      onFocus={() => setCurrentStyle({ ...baseStyle, ...hoverStyle, ...focusStyle })}
      onBlur={() => setCurrentStyle(baseStyle)}
    >
      <svg
        style={{
          width: '1.25rem',    // w-5 => 20px
          height: '1.25rem',   // h-5 => 20px
          marginRight: '0.5rem' // mr-2
        }}
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        viewBox="0 0 24 24"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M13 2L3 14h9l-1 8 10-12h-9l1-8z" />
      </svg>
      Gagner des XP
    </button>
  );
};

const StudentBasic = (props) => {
  var {
    perPageList,
    session,
    dashboardData,
    studentCustomOwnAchivement,
    studentOwnAchivementModal,
  } = props;
  var age = dashboardData?.data?.studentscore?.dob
    ? getAge(dashboardData?.data?.studentscore?.dob)
    : 0;
  // console.log("dashboardData :- ", dashboardData);
  // console.log("studentCustomOwnAchivement :- ", studentCustomOwnAchivement);
  const navigate = useNavigate();
  const openViewAchivement = () => {
    props.fetchStdOwnAchievements(session, 0, itemCount);
    props.viewAchivementModal({ show: true });
  };

  const [isOpen, setIsOpen] = useState(false);

  const renderAchivmentsList = () => {
    return _.map(studentCustomOwnAchivement?.data, (item, index) => {
      var icon =
        item?.type === "trophies"
          ? trophyIcon
          : item?.type === "badges"
          ? badgeIcon
          : item?.type === "medals" && item?.medaltype === "gold"
          ? goldMedalIcon
          : item?.type === "medals" && item?.medaltype === "silver"
          ? silverMedalIcon
          : item?.type === "medals" && item?.medaltype === "bronze"
          ? bronzeMedalIcon
          : "";

      return (
        <div key={`ach_key_${index}`} className="py-3">
          <div className="flex items-center space-x-5 2xs:space-x-6">
            <div className="flex-shrink-0">
              <span className="">
                {item?.type === "points" || item?.type === "bonus" ? (
                  <BadgeCheckIcon className="h-10 w-9 text-theme" />
                ) : (
                  <img className="inline h-10 w-9" src={icon} />
                  
                )}
                
              </span>
            </div>
            <div className="w-0 flex-1">
              <dl>
                <dt className="text-primary text-xl font-normal truncate capitalize">
                  {item?.questionsets_by_questionsetidfk?.setname}
                </dt>
                <dd>
                  <div className="text-secondary text-sm font-normal capitalize">
                    {LocaleStrings.points} {item?.points}
                    <span className="ml-2-">
                      {" "}
                      -{" "}
                      {convertDateIntoLocal(item?.createdon).format(
                        "MMM DD, YYYY"
                      )}
                    </span>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
    {isOpen && <Popup onClose={() => {console.log(isOpen);setIsOpen(false)}} />}
      <div className="lg:grid grid-cols-2 space-x-0 lg:space-x-4 space-y-4 lg:space-y-0">
        {/* Student Info */}
        <div className="border shadow-lg rounded-xl overflow-hidden">
          <div className="px-4 2xs:px-7 py-5 2xs:py-7">
            <div className="md:flex md:items-center md:justify-between">
              <div className="flex items-center space-x-3 2xs:space-x-5">
                <div className="flex-shrink-0 h-35 w-32 sm:h-56 sm:w-44 rounded-2xl bg-theme">
                  {dashboardData?.data?.studentscore?.image ? (
                    <img
                      className="inline-block h-full w-full object-cover rounded-2xl border"
                      src={
                        dashboardData?.data?.studentscore?.image
                          ? `${BASE_IMAGES_URL}/${dashboardData?.data?.studentscore?.image}?api_key=${session.apiKey}&session_token=${session.sessionToken}`
                          : ""
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="space-y-2">
                  <div className="text-3xl sm:text-4xl font-normal text-primary capitalize">
                    {dashboardData?.data?.studentscore?.username ?? "---"}
                  </div>
                  {/*
                  <div className="text-xl font-normal capitalize">
                    <span className="text-secondary">
                      {LocaleStrings.age}
                      {": "}
                    </span>
                    <span className="text-primary">
                      {age} {age > 1 ? LocaleStrings.year : LocaleStrings.year}
                    </span>
                  </div>
                    */}
                  <div className="hidden xs:block pt-2 text-left">
                    <div className="space-y-5">
                      <div>
                        {dashboardData?.data?.studentscore?.defaultclass ? (
                          <span
                            className="bg-theme rounded-md py-2 px-6 text-center inline-block"
                            style={{
                              backgroundColor:
                                dashboardData?.data?.studentscore?.classcolor,
                            }}
                          >
                            <span className="text-base font-normal text-white">
                              {dashboardData?.data?.studentscore?.defaultclass}
                            </span>
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="mt-2 space-x-2 2xs:space-x-3">
                      <span className="text-lg font-bold text-primary" >
                            Total XP : <span style={{color:"oklch(0.627 0.194 149.214)"}}>{dashboardData?.data?.studentscore?.points}</span>  - Cette semaine : + <span style={{color:"oklch(0.627 0.194 149.214)"}}>{dashboardData?.data?.studentscore?.week_points ? dashboardData?.data?.studentscore?.week_points : 0 }</span> XP

                          </span>
                      </div>

                      <div className="mt-2 space-x-2 2xs:space-x-3">
                        <span className="space-x-1">
                          <img className="inline h-7 w-7" src={goldMedalIcon} />
                          <span className="text-lg font-normal text-primary">
                            {dashboardData?.data?.studentscore?.medals_gold}
                          </span>
                        </span>
                        <span className="space-x-1">
                          <img
                            className="inline h-7 w-7"
                            src={silverMedalIcon}
                          />
                          <span className="text-lg font-normal text-primary">
                            {dashboardData?.data?.studentscore?.medals_silver}
                          </span>
                        </span>

                        <span className="space-x-1">
                          <img
                            className="inline h-7 w-7"
                            src={bronzeMedalIcon}
                          />
                          <span className="text-lg font-normal text-primary">
                            {dashboardData?.data?.studentscore?.medals_bronze}
                          </span>
                        </span>
                        
                      </div>
                      <InlineButton onClick={() => {console.log(isOpen);setIsOpen(true)}} />
                    </div>
                  </div>
                </div>
              </div>

              {/* For mobile */}
              <div className="block xs:hidden mt-4 sm:mt-0 text-left">
                <div className="space-x-1 2xs:space-x-3">
                  {dashboardData?.data?.studentscore?.defaultclass ? (
                    <span
                      className="bg-theme rounded-md py-1 px-4 text-center inline-block"
                      style={{
                        backgroundColor:
                          dashboardData?.data?.studentscore?.classcolor,
                      }}
                    >
                      <span className="text-base font-normal text-white">
                        {dashboardData?.data?.studentscore?.defaultclass}
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                  <span className="space-x-2">
                    <img className="inline h-9 w-9" src={trophyIcon} />
                    <span className="text-lg font-normal text-primary">
                      {dashboardData?.data?.studentscore?.trophy}
                    </span>
                  </span>
                  <span className="space-x-1">
                    <img className="inline h-9 w-9" src={badgeIcon} />
                    <span className="text-lg font-normal text-primary">
                      {dashboardData?.data?.studentscore?.badges}
                    </span>
                  </span>
                  <span className="space-x-1">
                    <img className="inline h-9 w-9" src={goldMedalIcon} />
                    <span className="text-lg font-normal text-primary">
                      {dashboardData?.data?.studentscore?.medals_gold}
                    </span>
                  </span>
                  <span className="space-x-1">
                    <img className="inline h-9 w-9" src={silverMedalIcon} />
                    <span className="text-lg font-normal text-primary">
                      {dashboardData?.data?.studentscore?.medals_silver}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Student achivments */}
        <div className="border shadow-lg rounded-xl overflow-hidden">
          {studentCustomOwnAchivement?.count > 0 ? (
            <>
              <div className="divide-y px-5 2xs:px-6 2xs:py-4">
                {renderAchivmentsList()}
              </div>
              <>
                {studentCustomOwnAchivement?.count > 3 ? (
                  <div className="text-center text-xl font-medium text-customgreen flex justify-center items-center h-8">
                    <span
                      onClick={openViewAchivement}
                      className="cursor-pointer hover:underline"
                    >
                      {LocaleStrings.button_view_all}
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </>
            </>
          ) : (
            <div className="custom-item-center w-full 2xl:w-80% 2xl:m-auto h-full p-4">
              <div className="text-center flex items-center space-x-3 2xs:space-x-5">
                <div className="mt-2 text-xl 2xs:text-2xl font-medium text-primary">
                  <img className="inline w-full h-full" src={EmptyActivityImage} />
                </div>
                <div className="mt-2 text-xl 2xs:text-2xl font-medium text-primary">
                  {LocaleStrings.dashboard_text_no_award_available}
                  <p>
                    <button onClick={() => navigate("/myspace")} className="text-lg font-light py-1.5 text-white w-full bg-gradient-to-r from-[#4ca8b2] to-[#f0a45e] rounded-3xl">Réponds à ton premier quizz</button>
                  </p>
                  
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {studentOwnAchivementModal?.show ? (
        <StudentRewardsModal perPageList={perPageList} />
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    dashboardData: state.dashboardData,
    studentCustomOwnAchivement: state.studentCustomOwnAchivement,
    studentOwnAchivementModal: state.studentOwnAchivementModal,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { viewAchivementModal, fetchStdOwnAchievements },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StudentBasic);
