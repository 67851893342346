import React, { useState, useEffect, useCallback } from 'react';

// Service de notification global (singleton)
let notificationCallbacks = [];
let notificationContainer = null;

// Enregistrer le composant container
const registerNotificationContainer = (callbacks) => {
  notificationCallbacks = callbacks;
  notificationContainer = true;
  return () => {
    notificationContainer = null;
    notificationCallbacks = [];
  };
};

// API globale pour les notifications
// Ajout d'un paramètre options pour définir le style (backgroundColor, textColor)
export const notification = {
  add: (message, type = 'info', options = {}) => {
    if (!notificationContainer) {
      console.warn("NotificationContainer n'est pas monté dans le DOM");
      return null;
    }
    return notificationCallbacks.addNotification(message, type, options);
  },
  remove: (id) => {
    if (!notificationContainer) return;
    notificationCallbacks.removeNotification(id);
  }
};

// Composant pour une notification individuelle
const Notification = ({ message, id, type, removeNotification, options = {} }) => {
  const timerRef = React.useRef();

  useEffect(() => {
    // Supprimer la notification après 4 secondes
    timerRef.current = setTimeout(() => {
      removeNotification(id);
    }, 4000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [id, removeNotification]);

  // Définition des styles en fonction des options fournies
  const notificationStyle = {
    backgroundColor: options.backgroundColor || '#ffffff',
    color: options.textColor || '#000000'
  };

  return (
    <div
      className="shadow-lg rounded-md p-4 mb-2 mx-2 animate-fadeIn flex items-center justify-between"
      style={notificationStyle}
    >
      <span>{message}</span>
    </div>
  );
};

// Conteneur de notifications (à placer à la racine sans wrapper d'autres composants)
export const NotificationComp = () => {
  const [notifications, setNotifications] = useState([]);

  const removeNotification = useCallback((id) => {
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  }, []);

  // Fonction pour ajouter une nouvelle notification avec options
  const addNotification = useCallback((message, type = 'info', options = {}) => {
    const id = Date.now() + Math.floor(Math.random() * 1000);
    setNotifications(prev => [...prev, { id, message, type, options }]);
    return id;
  }, []);

  // Enregistrer ce composant comme container de notifications global
  useEffect(() => {
    const unregister = registerNotificationContainer({
      addNotification,
      removeNotification
    });
    return unregister;
  }, [addNotification, removeNotification]);

  // Styles pour l'animation de fade-in
  const styles = `
    @keyframes fadeIn {
      from { opacity: 0; transform: translateY(-10px); }
      to { opacity: 1; transform: translateY(0); }
    }
    
    .animate-fadeIn {
      animation: fadeIn 0.3s ease-out forwards;
    }
  `;

  return (
    <div className="fixed top-0 left-0 right-0 z-50 flex flex-col items-center" style={{ marginTop: '8em' }}>
      <style>{styles}</style>
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          id={notification.id}
          message={notification.message}
          type={notification.type}
          options={notification.options}
          removeNotification={removeNotification}
        />
      ))}
    </div>
  );
};

export default NotificationComp;
