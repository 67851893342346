import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import {AI_URL} from "../../../components/common/constant";
const History = forwardRef(({ userid, loader, onnew }, ref) => {
  
  const [isOpen, setIsOpen] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };



  const loadHistory = () => {
    fetch(`https://${AI_URL}/api/v1/ai_history`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      // Ajoutez ici le body si nécessaire. Exemple :
      body: JSON.stringify({
        "userid": userid
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des données");
        }
        return response.json();
      })
      .then(data => {
        // On suppose que l'API renvoie un tableau d'objets avec au moins "id" et "title"
        setConversations(data.data);
        
      })
      .catch(err => {
        setError(err);

      });
  }

  useImperativeHandle(ref, () => ({
    loadHistory
  }));

  useEffect(() => {

    loadHistory();
  }, []);

  return (
    <div className={`flex overflow-hidden`} style={{ pointerEvents: 'none', maxHeight:'97%' }}>
      <svg
        onClick={toggleSidebar}
        className="absolute z-10 cursor-pointer"
        style={{ pointerEvents: 'auto' }}
        width="32"
        height="32"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.85719 3L13.5 3C14.0523 3 14.5 3.44772 14.5 4C14.5 4.55229 14.0523 5 13.5 5H11.5V19H15.1C16.2366 19 17.0289 18.9992 17.6458 18.9488C18.2509 18.8994 18.5986 18.8072 18.862 18.673C19.4265 18.3854 19.8854 17.9265 20.173 17.362C20.3072 17.0986 20.3994 16.7509 20.4488 16.1458C20.4992 15.5289 20.5 14.7366 20.5 13.6V11.5C20.5 10.9477 20.9477 10.5 21.5 10.5C22.0523 10.5 22.5 10.9477 22.5 11.5V13.6428C22.5 14.7266 22.5 15.6008 22.4422 16.3086C22.3826 17.0375 22.2568 17.6777 21.955 18.27C21.4757 19.2108 20.7108 19.9757 19.77 20.455C19.1777 20.7568 18.5375 20.8826 17.8086 20.9422C17.1008 21 16.2266 21 15.1428 21H8.85717C7.77339 21 6.89925 21 6.19138 20.9422C5.46253 20.8826 4.82234 20.7568 4.23005 20.455C3.28924 19.9757 2.52433 19.2108 2.04497 18.27C1.74318 17.6777 1.61737 17.0375 1.55782 16.3086C1.49998 15.6007 1.49999 14.7266 1.5 13.6428V10.3572C1.49999 9.27341 1.49998 8.39926 1.55782 7.69138C1.61737 6.96253 1.74318 6.32234 2.04497 5.73005C2.52433 4.78924 3.28924 4.02433 4.23005 3.54497C4.82234 3.24318 5.46253 3.11737 6.19138 3.05782C6.89926 2.99998 7.77341 2.99999 8.85719 3ZM9.5 19V5H8.9C7.76339 5 6.97108 5.00078 6.35424 5.05118C5.74907 5.10062 5.40138 5.19279 5.13803 5.32698C4.57354 5.6146 4.1146 6.07354 3.82698 6.63803C3.69279 6.90138 3.60062 7.24907 3.55118 7.85424C3.50078 8.47108 3.5 9.26339 3.5 10.4V13.6C3.5 14.7366 3.50078 15.5289 3.55118 16.1458C3.60062 16.7509 3.69279 17.0986 3.82698 17.362C4.1146 17.9265 4.57354 18.3854 5.13803 18.673C5.40138 18.8072 5.74907 18.8994 6.35424 18.9488C6.97108 18.9992 7.76339 19 8.9 19H9.5ZM5 8.5C5 7.94772 5.44772 7.5 6 7.5H7C7.55229 7.5 8 7.94772 8 8.5C8 9.05229 7.55229 9.5 7 9.5H6C5.44772 9.5 5 9.05229 5 8.5ZM5 12C5 11.4477 5.44772 11 6 11H7C7.55229 11 8 11.4477 8 12C8 12.5523 7.55229 13 7 13H6C5.44772 13 5 12.5523 5 12Z"
          fill="currentColor"
        />
        <circle cx="20" cy="5" r="4" fill="#0285FF" />
      </svg>
      <div
        className={`mt-10 w-64 bg-gray-100 border-l border-gray-300 transform transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0 overflow-y-auto' : '-translate-x-full'
        }`}
        style={{ pointerEvents: 'auto', maxHeight:'50%' }}
      >
        <div className="pt-4"
          style={{ pointerEvents: 'auto', maxHeight:'50%' }}
        >
          <h2 className="text-xl font-bold mb-4 mt-4">Historique des conversations</h2>
          {loading && <p>Chargement...</p>}
          {error && <p>Erreur : {error.message}</p>}
          {!loading && !error && (
            <ul>
                <li class="primaryhover" onClick={() => onnew()} className="p-2 hover:bg-sky-700 primaryhover">
                  Nouvelle conversation
                </li>
                {conversations.map((conv) => (
                  conv.title && (
                    <li onClick={() => loader(JSON.parse(conv.data), conv.id)} key={conv.id} className="p-2 primaryhover">
                      {conv.title.length > 50 ? conv.title.slice(0, 50) + "..." : conv.title}
                    </li>
                  )
                ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
});

export default History;