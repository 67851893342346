import React, { useState } from 'react';
import {AI_URL} from "../../../components/common/constant";
function FeedbackPopup() {
  const [isOpen, setIsOpen] = useState(false);
  const [feedbackValue, setFeedbackValue] = useState("");

  const ouvrirPopup = () => {
    setIsOpen(true);
  };

  const fermerPopup = () => {
    setIsOpen(false);
  };

  const sendFeedback = async () => {
    try {
      const response = await fetch(`https://${AI_URL}/api/v1/feedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "feed": feedbackValue }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Feedback envoyé avec succès:", data);

      // Vous pouvez ensuite fermer la popup ou réinitialiser le champ
      setFeedbackValue("");
      fermerPopup();
    } catch (error) {
      console.error("Erreur lors de l'envoi du feedback:", error);
    }
  };

  return (
    <div className="relative">
      <button 
        onClick={ouvrirPopup}
        className="w-full sm:w-30 p-3 sm:p-4 bg-theme text-white rounded-lg text-center"
      >
        Feedback
      </button>

      {isOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
          <div 
            className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg mx-4 sm:mx-auto"
          >
            <h2 className="text-2xl font-semibold mb-4">Laisser un feedback</h2>
            <p className="mb-4">Dans une démarche d’amélioration continue de nos services, nous recueillons vos retours d’expérience afin d’optimiser l’utilisation de notre site et de mieux répondre à vos attentes. :</p>
            <textarea 
              rows="4" 
              className="w-full p-2 border border-gray-300 rounded mb-4" 
              placeholder="Votre avis"
              value={feedbackValue}
              onChange={(e) => setFeedbackValue(e.target.value)}
            ></textarea>

            <div className="flex justify-between items-center mt-4">
                <button 
                    onClick={sendFeedback}
                    className="p-3 bg-theme text-white rounded-lg sm:w-auto"
                >
                    Envoyer
                </button>

                <button 
                    onClick={fermerPopup}
                    className="p-3 bg-theme text-white rounded-lg sm:w-auto"
                >
                    Fermer
                </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FeedbackPopup;
